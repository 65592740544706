// third-party
import { ContentPasteSearchOutlined } from '@mui/icons-material';
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { APP_API_URL, Trace } from '../../config';

// ----------------------------------------------------------------------
const apiURL = APP_API_URL;

const initialState = {
    error: null,
    counts: [],
    cycleCount: null,
    bins: [],
    selectedSiteId: 2
};

const slice = createSlice({
    name: 'inventory',
    initialState,
    reducers: {

        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        //reset cyclecount to null
        setSiteIdSuccess(state, action) {
            state.selectedSiteId = action.payload;
        },
        // Get Counts
        getCountListSuccess(state, action) {
            state.counts = action.payload;
        },
        //Update bin count
        updateBinCountSuccess(state, action) {
            Trace(action.payload);
        },
        //Get specific count
        getCountSuccess(state, action) {
            state.cycleCount = action.payload;
        },
        //Get new count
        getNewCountSuccess(state, action) {
            state.cycleCount = action.payload;
        },
        getSiteBinsListSuccess(state, action) {
            state.bins = action.payload;
        },
        //Delete count
        deleteCycleCountSuccess(state, action) {
            Trace(action.payload);
            getCountsForSite(state.selectedSiteId);
        },
        //Post count
        postCycleCountSuccess(state, action) {
            Trace(action.payload);
            getCountsForSite(state.selectedSiteId);
        },
        //update cycle count properties
        updateCycleCountSuccess(state, action) {
            Trace(action.payload);
        },
        updateBinPropertiesSuccess(state, action) {
            Trace(action.payload);
        }
    }
});

// Reducer
export default slice.reducer;
//--------------------Inventory Counting API routes & utils
export function postCycleCountToD365(CycleCount) {
    return async () => {
        try {
            Trace("Posting count");
            if (CycleCount.IsPosted) {
                dispatch(slice.actions.hasError("Cannot post posted counts"));
            } else {
                const response = await axios({
                    method: 'GET',
                    url: `${apiURL}/cycleCounts/cycleCount/post/${CycleCount.id}`
                }).then((response) => {
                    Trace('Post count');
                    dispatch(slice.actions.postCycleCountSuccess(response.data));
                });
            }
        } catch (error) {
            console.log("Error post count");
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function setSelectedSiteId(SiteId) {
    dispatch(slice.actions.setSiteIdSuccess(SiteId));
}

export function getBinsForSite(SiteId) {
    return async () => {
        try {
            Trace("Getting for " + SiteId);
            const response = await axios.get(`${apiURL}/bins/site/${SiteId}`);
            dispatch(slice.actions.getSiteBinsListSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getCountsForSite(SiteId) {
    return async () => {
        try {
            console.log("Getting for " + SiteId);
            const response = await axios.get(`${apiURL}/cycleCounts/cycleCount/site/${SiteId}`);
            dispatch(slice.actions.getCountListSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getCountById(CycleCountId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/cycleCounts/cycleCount/${CycleCountId}`);
            dispatch(slice.actions.getCountSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getNewCountForSite(SiteId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/cycleCounts/cycleCount/new/${SiteId}`);
            dispatch(slice.actions.getNewCountSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteCycleCount(CycleCount) {
    return async () => {
        try {
            console.log("Deleting count");
            if (CycleCount.IsPosted) {
                dispatch(slice.actions.hasError("Cannot delete posted counts"));
            } else {
                const response = await axios({
                    method: 'DELETE',
                    url: `${apiURL}/cycleCounts/cycleCount/${CycleCount.id}`
                }).then((response) => {
                    console.log('Deleted count');
                    dispatch(slice.actions.deleteCycleCountSuccess(response.data));
                });
            }
        } catch (error) {
            console.log("Error deleting count");
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function resetCycleCount(state) {
    state.cycleCount = initialState.cycleCount;
    state.counts = initialState.counts;
};

export function updateCycleCount(updatedRecord, CycleCountId) {
    return async () => {
        try {
            console.log("Updating Cycle Count");
            const response = await axios({
                method: 'PUT',
                url: `${apiURL}/cycleCounts/cycleCount/${CycleCountId}`,
                data: JSON.stringify(updatedRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Updated Bin Count');
                dispatch(slice.actions.updateCycleCountSuccess(response.data));
            });
        } catch (error) {
            console.log("Error updating cycle count");
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateCountForBin(updatedRecord, CycleCountBinId) {
    return async () => {
        try {
            Trace("Updating Bin count");
            const response = await axios({
                method: 'PUT',
                url: `${apiURL}/cycleCount/cycleCountBin/${CycleCountBinId}`,
                data: JSON.stringify(updatedRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Updated Bin Count');
                dispatch(slice.actions.updateBinCountSuccess(response.data));
            });
        } catch (error) {
            console.log("Error updating bin count");
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateBinProperties(updatedBins) {
    return async () => {
        try {
            Trace("Updating Bin Properties");
            const response = await axios({
                method: 'PUT',
                url: `${apiURL}/bins/update`,
                data: JSON.stringify(updatedBins),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                Trace('Updated Bin Count');
                dispatch(slice.actions.updateCycleCountSuccess(response.data));
            });
        } catch (error) {
            console.log("Error updating bin properties.");
            dispatch(slice.actions.hasError(error));
        }
    };
}