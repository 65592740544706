// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconPool, IconSeeding, IconFlame } from '@tabler/icons';
import FireplaceOutlinedIcon from '@mui/icons-material/FireplaceOutlined';

// constant
const icons = {
    IconPool,
    FireplaceOutlinedIcon,
    IconSeeding,
    IconFlame
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const recipes = {
    id: 'recipes',
    title: <FormattedMessage id="recipes" />,
    type: 'group',
    children: [
        {
            id: 'steep-page',
            title: <FormattedMessage id="steep" />,
            type: 'item',
            url: '/recipes/steep',
            icon: icons.IconPool,
            breadcrumbs: false
        },
        {
            id: 'germ-page',
            title: <FormattedMessage id="germination" />,
            type: 'item',
            url: '/recipes/germ',
            icon: icons.IconSeeding,
            breadcrumbs: false
        },
        {
            id: 'kiln-page',
            title: <FormattedMessage id="kiln" />,
            type: 'item',
            url: '/recipes/kiln',
            icon: icons.FireplaceOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'roast-page',
            title: <FormattedMessage id="roast" />,
            type: 'item',
            url: '/recipes/roast',
            icon: icons.IconFlame,
            breadcrumbs: false
        }
    ]
};

export default recipes;
