import { createContext, useState, useEffect } from "react";

import {
    InteractionRequiredAuthError,
    InteractionStatus,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { loginRequestScope, accessTokenScope } from "authConfig";
import { APP_UI_URL } from "config";
import { useDispatch } from 'store';

const AzureMSALContext = createContext(null);

export const AzureMSALProvider = ({ children }) => {

    const { instance, accounts, inProgress } = useMsal();
    const [accessToken, setAccessToken] = useState(null);
    const [userRole, setUserRole] = useState([null]);
    const [userSite, setUserSite] = useState(null);
    const [isGlobalAdmin, setIsGlobalAdmin] = useState(false);
    const [error, setError] = useState(null);
    const [isRefreshingToken, setIsRefreshingToken] = useState(false);
    const loginType = "redirect";

    const hasValidToken = () => {
        const token = sessionStorage.getItem('access_token');
        if (token !== "" && token !== null && token !== undefined) {
            return true;
        } else {
            getToken();
            return false
        }
    }

    const login = async () => {
        try {
            if (loginType === "popup") {
                const result = await instance.loginPopup(loginRequestScope).catch(e => {
                    console.log(e);
                });
            } else if (loginType === "redirect") {
                const result = instance.loginRedirect(loginRequestScope).catch(e => {
                    console.log(e);
                });
            }
        } catch (e) {
            setError(e.message);
        }
    };

    const logout = async () => {
        try {
            sessionStorage.removeItem("access_token");
            instance.logoutRedirect({
                postLogoutRedirectUri: APP_UI_URL
            })
            await instance.logout();
        } catch (e) {
            sessionStorage.removeItem("access_token");
            console.error(e);
            setError(e.message);
        }
    };

    const getToken = () => {
        const accessTokenRequest = {
            ...accessTokenScope,
            account: accounts[0]
        };

        if (inProgress === InteractionStatus.None) {
            instance
                .acquireTokenSilent(accessTokenRequest)
                .then((accessTokenResponse) => {
                    // Acquire token silent success
                    setAccessToken(accessTokenResponse.accessToken);
                    sessionStorage.setItem("access_token", accessTokenResponse.accessToken);
                    sessionStorage.setItem("token_expiry", accessTokenResponse.expiresOn);
                    scheduleRefreshToken(accessTokenResponse.expiresOn);
                    return accessTokenResponse.accessToken
                })
                .catch((error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                        instance
                            .acquireTokenRedirect(accessTokenRequest)
                            .then((accessTokenResponse) => {
                                setAccessToken(accessTokenResponse.accessToken);
                                sessionStorage.setItem("access_token", accessTokenResponse.accessToken);
                                sessionStorage.setItem("token_expiry", accessTokenResponse.expiresOn);
                                scheduleRefreshToken(accessTokenResponse.expiresOn);
                                return accessTokenResponse.accessToken;
                            });
                    }
                    console.log(error);
                });
        }
    };

    const scheduleRefreshToken = (expiresOn) => {
        const delay = expiresOn - Date.now() - 30000; // Refresh token 30 seconds before expiration
        setTimeout(refreshToken, delay);
    }

    const refreshToken = () => {
        setIsRefreshingToken(true);
        console.log("Token Expired Refreshing Token");
        instance
            .acquireTokenSilent({
                ...accessTokenScope,
                account: accounts[0]
            })
            .then((accessTokenResponse) => {
                setAccessToken(accessTokenResponse.accessToken);
                sessionStorage.setItem("access_token", accessTokenResponse.accessToken);
                sessionStorage.setItem("token_expiry", accessTokenResponse.expiresOn);
                scheduleRefreshToken(accessTokenResponse.expiresOn);
            })
            .catch((error) => {
                setIsRefreshingToken(false);
                setError(error);
            });
    }

    const setSite = (roles) => {
        if (roles !== undefined && roles !== null) {
            if (roles.find((r) => r.split('.')[0] === 'Admin') !== undefined) {
                setUserSite('ADMIN');
            } else {
                setUserSite(roles[0].split('.')[0]);
            }
        }
    }

    const setRole = (roles) => {
        if (roles !== undefined && roles !== null) {
            if (roles.find((r) => r.split('.')[0] === 'Admin') !== undefined) {
                setUserRole('Admin');
            } else {
                setUserRole(roles[0].split('.')[1]);
            }
        }
    }

    const setGlobalAdminFlag = (roles) => {
        if (roles !== undefined && roles !== null) {
            if (roles[0] === 'Admin' && roles[0].split('.').length === 1) {
                setIsGlobalAdmin(true);
            } else {
                setIsGlobalAdmin(false);
            }
        }
    }

    useEffect(() => {
        if (accounts.length > 0) {
            getToken();

            if (accounts[0].idTokenClaims !== undefined && accounts[0].idTokenClaims !== null &&
                accounts[0].idTokenClaims.roles !== undefined && accounts[0].idTokenClaims.roles !== null) {
                setGlobalAdminFlag(accounts[0].idTokenClaims.roles);
                setRole(accounts[0].idTokenClaims.roles);
                setSite(accounts[0].idTokenClaims.roles);
            }
        }
    }, [accounts]);

    return (
        <AzureMSALContext.Provider
            value={{
                error,
                login,
                logout,
                getToken,
                accessToken,
                hasValidToken,
                userRole,
                userSite,
                isGlobalAdmin
            }}
        >
            {children}
        </AzureMSALContext.Provider>
    );
};

export default AzureMSALContext;