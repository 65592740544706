// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { APP_API_URL } from '../../config';

// ----------------------------------------------------------------------
const apiURL = APP_API_URL;

const initialState = {
    error: null,
    roastId: 0,
    roastRow: null,
    recipes: [],
    reductSteps: []
};

const slice = createSlice({
    name: 'recipe',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // Get Recipe
        getRecipeSuccess(state, action) {
            state.recipes = action.payload;
        },

        // Get Fill Drain On Steps
        getRecipeReductSuccess(state, action) {
            state.reductSteps = action.payload;
        },

        // Set RoastId
        storeRoastId(state, action) {
            state.roastId = action.payload;
        },
        // Set RoastId
        storeKilnRow(state, action) {
            state.roastRow = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function setRoastId(id) {
    dispatch(slice.actions.storeRoastId(id));
}

export function setRoastSelectedRow(roastRow) {
    dispatch(slice.actions.storeKilnRow(roastRow));
}

export function getRoastRecipes() {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/recipes/roast`);
            dispatch(slice.actions.getRecipeSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getRoastRecipesBySite(SiteId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/recipes/roast/all/site/${SiteId}`);
            dispatch(slice.actions.getRecipeSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getRoastRecipesByRoute(RouteNumber) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/recipes/roast/all/route/${RouteNumber}`);
            dispatch(slice.actions.getRecipeSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getRoastRecipesByMalthouse(siteId, maltHouseId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/recipes/roast/all/${siteId}/${maltHouseId}`);
            dispatch(slice.actions.getRecipeSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function duplicateRoastRecipe(newRecipeName, RoastId) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/data/roast/${RoastId}/copy`,
                data: JSON.stringify({
                    recipeName: newRecipeName
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log(`Duplicate Roast:${RoastId}`);
                dispatch(getRoastRecipes());
                console.log('Get Roast list');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createRoastRecipe(newRecord, siteId = 0) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/recipes/roast`,
                data: JSON.stringify(newRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('created');
                if (siteId === 0) {
                    dispatch(getRoastRecipes());
                } else {
                    dispatch(getRoastRecipesBySite(siteId));
                }
                console.log('get roast list');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editRoastRecipe(updatedRecord, RoastId, siteId = 0) {
    return async () => {
        try {
            const response = await axios({
                method: 'PUT',
                url: `${apiURL}/recipes/roast/${RoastId}`,
                data: JSON.stringify(updatedRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Updated');
                if (siteId === 0) {
                    dispatch(getRoastRecipes());
                } else {
                    dispatch(getRoastRecipesBySite(siteId));
                }
                console.log('Get roast list');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteRoastRecipe(RoastId, siteId = 0) {
    return async () => {
        try {
            const response = await axios.delete(`${apiURL}/recipes/roast/${RoastId}`).then((response) => {
                console.log('Deleted');
                if (siteId === 0) {
                    dispatch(getRoastRecipes());
                } else {
                    dispatch(getRoastRecipesBySite(siteId));
                }
                console.log('Done Delete');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editRoastStep(updatedRecord, RoastId, StepNumber, ScheduleType) {
    return async () => {
        try {
            const response = await axios({
                method: 'PUT',
                url: `${apiURL}/recipes/roast/${RoastId}/${ScheduleType}/${StepNumber}`,
                data: JSON.stringify(updatedRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Updated');
                switch (ScheduleType) {
                    case 'reduct':
                        dispatch(getRoastReductStepsById(RoastId));
                        break;
                }
                console.log('Get Roast list');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// -----------------------------------------------------
// Reduct
export function getRoastReductStepsById(RoastId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/recipes/roast/${RoastId}/reduct/`);
            dispatch(slice.actions.getRecipeReductSuccess(response.data));
            console.log('Get Roast');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteRoastReductStep(RoastId, StepNumber) {
    return async () => {
        try {
            const response = await axios
                .delete(`${apiURL}/recipes/roast/${RoastId}/reduct/${StepNumber}`)
                .then((response) => {
                    console.log('Deleted');
                    dispatch(getRoastReductStepsById(RoastId));
                    console.log('Done Delete');
                });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createRoastReductStep(RoastId, newRecord) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/recipes/roast/${RoastId}/reduct`,
                data: JSON.stringify(newRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('created');
                dispatch(getRoastReductStepsById(RoastId));
                console.log('get Fill Drain Steps List');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function toggleArchiveRoastReductStep(RoastId, StepNumber, Archive) {
    // TODO: Change to archived column
    const body = [
        {
            path: 'archived',
            op: 'add',
            value: Archive
        }
    ];
    return async () => {
        try {
            const response = await axios({
                method: 'PATCH',
                url: `${apiURL}/recipes/roast/${RoastId}/reduct/${StepNumber}`,
                data: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Archived');
                dispatch(getRoastReductStepsById(RoastId));
                console.log('Done archive');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
