// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { APP_API_URL } from '../../config';

// ----------------------------------------------------------------------
const apiURL = APP_API_URL;

const initialState = {
    error: null,
    steepId: 0,
    steepRow: null,
    recipes: [],
    fillDrainSteps: []
};

const slice = createSlice({
    name: 'recipe',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // Get Recipe
        getRecipeSuccess(state, action) {
            state.recipes = action.payload;
        },

        // Get Fill Drain On Steps
        getRecipeFillDrainSuccess(state, action) {
            state.fillDrainSteps = action.payload;
        },

        // Set SteepId
        storeSteepId(state, action) {
            state.steepId = action.payload;
        },
        // Set SteepId
        storeKilnRow(state, action) {
            state.steepRow = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function setSteepId(id) {
    dispatch(slice.actions.storeSteepId(id));
}

export function setSteepSelectedRow(steepRow) {
    dispatch(slice.actions.storeKilnRow(steepRow));
}

export function getSteepRecipes() {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/recipes/steep`);
            dispatch(slice.actions.getRecipeSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getSteepRecipesBySite(SiteId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/recipes/steep/all/site/${SiteId}`);
            dispatch(slice.actions.getRecipeSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getSteepRecipesByRoute(RouteNumber) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/recipes/steep/all/route/${RouteNumber}`);
            dispatch(slice.actions.getRecipeSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getSteepRecipesByMalthouse(siteId, maltHouseId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/recipes/steep/all/${siteId}/${maltHouseId}`);
            dispatch(slice.actions.getRecipeSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function duplicateSteepRecipe(SteepId, newRecipe) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/data/steep/${SteepId}/copy`,
                data: JSON.stringify(newRecipe),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log(`Duplicate Steep:${SteepId}`);
                dispatch(getSteepRecipes());
                console.log('Get Steep list');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createSteepRecipe(newRecord, siteId = 0) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/recipes/steep`,
                data: JSON.stringify(newRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('created');
                if (siteId === 0) {
                    dispatch(getSteepRecipes());
                } else {
                    dispatch(getSteepRecipesBySite(siteId));
                }
                console.log('get steep list');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editSteepRecipe(updatedRecord, SteepId, siteId = 0) {
    return async () => {
        try {
            const response = await axios({
                method: 'PUT',
                url: `${apiURL}/recipes/steep/${SteepId}`,
                data: JSON.stringify(updatedRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Updated');
                if (siteId === 0) {
                    dispatch(getSteepRecipes());
                } else {
                    dispatch(getSteepRecipesBySite(siteId));
                }
                console.log('Get steep list');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteSteepRecipe(SteepId, siteId = 0) {
    return async () => {
        try {
            const response = await axios.delete(`${apiURL}/recipes/steep/${SteepId}`).then((response) => {
                console.log('Deleted');
                if (siteId === 0) {
                    dispatch(getSteepRecipes());
                } else {
                    dispatch(getSteepRecipesBySite(siteId));
                }
                console.log('Done Delete');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editSteepStep(updatedRecord, SteepId, StepNumber, ScheduleType) {
    return async () => {
        try {
            const response = await axios({
                method: 'PUT',
                url: `${apiURL}/recipes/steep/${SteepId}/${ScheduleType}/${StepNumber}`,
                data: JSON.stringify(updatedRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Updated');
                switch (ScheduleType) {
                    case 'filldrain':
                        dispatch(getSteepFillDrainStepsById(SteepId));
                        break;
                }
                console.log('Get Steep list');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// -----------------------------------------------------
// Fill Drain
export function getSteepFillDrainStepsById(SteepId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/recipes/steep/${SteepId}/filldrain/`);
            dispatch(slice.actions.getRecipeFillDrainSuccess(response.data));
            console.log('Get Steep');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteSteepFillDrainStep(SteepId, StepNumber) {
    return async () => {
        try {
            const response = await axios
                .delete(`${apiURL}/recipes/steep/${SteepId}/filldrain/${StepNumber}`)
                .then((response) => {
                    console.log('Deleted');
                    dispatch(getSteepFillDrainStepsById(SteepId));
                    console.log('Done Delete');
                });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createSteepFillDrainStep(SteepId, newRecord) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/recipes/steep/${SteepId}/filldrain`,
                data: JSON.stringify(newRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('created');
                dispatch(getSteepFillDrainStepsById(SteepId));
                console.log('get Fill Drain Steps List');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function toggleArchiveSteepFillDrainStep(SteepId, StepNumber, Archive) {
    // TODO: Change to archived column
    const body = [
        {
            path: 'archived',
            op: 'add',
            value: Archive
        }
    ];
    return async () => {
        try {
            const response = await axios({
                method: 'PATCH',
                url: `${apiURL}/recipes/steep/${SteepId}/filldrain/${StepNumber}`,
                data: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Archived');
                dispatch(getSteepFillDrainStepsById(SteepId));
                console.log('Done archive');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
