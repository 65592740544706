import { useContext, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import MainRoutesAdmin from './MainRoutesAdmin';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import AzureMSALContext from 'contexts/AzureMSALContext';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes({ auth }) {
    const { isGlobalAdmin } = useContext(AzureMSALContext);
    let routes = []
    if (auth === true) {
        if (isGlobalAdmin) {
            routes = [MainRoutesAdmin];
        } else {
            routes = [MainRoutes];
        }
    } else if (auth === false) {
        routes = [AuthenticationRoutes, LoginRoutes];
    }
    return useRoutes(routes);
}
