import { useEffect } from 'react';

// routing
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';

//Authentication
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { AzureMSALProvider as AuthProvider } from 'contexts/AzureMSALContext';

import './assets/fumesStyle.css';

// ==============================|| APP ||============================== //

const App = () => {
    const isAuthenticated = useIsAuthenticated();
    const { accounts } = useMsal();

    useEffect(() => {
        if (accounts.length > 0) {

            if (accounts[0].idTokenClaims !== undefined && accounts[0].idTokenClaims !== null &&
                accounts[0].idTokenClaims.roles !== undefined && accounts[0].idTokenClaims.roles !== null) {
                console.log("has role");
            } else {
                console.log("does not have role");
            }
        }
    }, [accounts]);

    const hasRole = () => {
        if (accounts !== undefined && accounts !== null && accounts.length > 0 && (
            accounts[0].idTokenClaims !== undefined && accounts[0].idTokenClaims !== null &&
            accounts[0].idTokenClaims.roles !== undefined && accounts[0].idTokenClaims.roles !== null
        )
        ) {
            return true;
        }
        else {
            return false;
        }
    }
    return (<ThemeCustomization>
        {/* RTL layout */}
        <RTLLayout>
            <Locales>
                <NavigationScroll>
                    <AuthProvider>
                        <>
                            {isAuthenticated && hasRole() ? <Routes auth={true} /> : <Routes auth={false} />}
                            <Snackbar />
                        </>
                    </AuthProvider>
                </NavigationScroll>
            </Locales>
        </RTLLayout>
    </ThemeCustomization>)
};

export default App;
