import { LicenseInfo } from '@mui/x-license-pro';

export const JWT_API = {
    secret: 'SECRET-KEY',
    timeout: '1 days'
};

export const FIREBASE_API = {
    apiKey: 'AIzaSyBernKzdSojh_vWXBHt0aRhf5SC9VLChbM',
    authDomain: 'berry-material-react.firebaseapp.com',
    projectId: 'berry-material-react',
    storageBucket: 'berry-material-react.appspot.com',
    messagingSenderId: '901111229354',
    appId: '1:901111229354:web:a5ae5aa95486297d69d9d3',
    measurementId: 'G-MGJHSL8XW3'
};

export const AUTH0_API = {
    client_id: '7T4IlWis4DKHSbG8JAye4Ipk0rvXkH9V',
    domain: 'dev-w0-vxep3.us.auth0.com'
};

export const AWS_API = {
    poolId: 'us-east-1_AOfOTXLvD',
    appClientId: '3eau2osduslvb7vks3vsh9t7b0'
};

LicenseInfo.setLicenseKey('da053607d0510c4707f87267d67c7e93Tz01OTUzNyxFPTE3MDc1ODEzMDY1NDUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');


// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

export const DASHBOARD_PATH = '/dashboard/default';

//Local
// export const APP_CLIENT_ID = '7aaaeae1-f374-423e-9f72-3a611968befd'
// export const APP_API_URL = 'https://localhost:5001/api';
// export const APP_SIGNALR_URL = 'https://localhost:5001/fumes-hub';
// export const APP_UI_URL = 'http://localhost:3000/auth';
// export const APP_ACCESS_SCOPE = 'api://7aaaeae1-f374-423e-9f72-3a611968befd/.default'
// export const APP_D365_PATH = 'https://umg-d365-spt.sandbox.operations.dynamics.com/'
// export const APP_ENV_THEME_PRESET = 'local';
// export const APP_ENV_HEADER_LABEL = 'LOCAL';

//Develop
// export const APP_CLIENT_ID = '7aaaeae1-f374-423e-9f72-3a611968befd'
// export const APP_API_URL = 'https://fumesnonprod.azurewebsites.net/api'
// export const APP_SIGNALR_URL = 'https://fumesnonprod.azurewebsites.net/fumes-hub';
// export const APP_UI_URL = 'https://fumesdev.azurewebsites.net/'
// export const APP_ACCESS_SCOPE = 'api://7aaaeae1-f374-423e-9f72-3a611968befd/.default'
// export const APP_D365_PATH = 'https://umg-d365-spt.sandbox.operations.dynamics.com/'
// export const APP_ENV_THEME_PRESET = 'dev';
// export const APP_ENV_HEADER_LABEL = 'DEV';

//UAT
// export const APP_CLIENT_ID = 'd5e43a5a-8320-44f3-ae61-9ed84a6d95ac'
// export const APP_API_URL = 'https://fumesuatapi.azurewebsites.net/api'
// export const APP_SIGNALR_URL = 'https://fumesuatapi.unitedmalt.com/fumes-hub'
// export const APP_UI_URL = 'https://fumesuat.azurewebsites.net/'
// export const APP_ACCESS_SCOPE = 'api://d5e43a5a-8320-44f3-ae61-9ed84a6d95ac/.default'
// export const APP_D365_PATH = 'https://umg-d365-spt.sandbox.operations.dynamics.com/'
// export const APP_ENV_THEME_PRESET = 'uat';
// export const APP_ENV_HEADER_LABEL = 'UAT';

//Production
export const APP_CLIENT_ID = '1ad7d17a-981c-4272-9b13-b1ac7f9b4144'
export const APP_API_URL = 'https://fumesapina.unitedmalt.com/api'
export const APP_SIGNALR_URL = 'https://fumesapina.unitedmalt.com/fumes-hub'
export const APP_UI_URL = 'https://fumesna.unitedmalt.com/'
export const APP_ACCESS_SCOPE = 'api://1ad7d17a-981c-4272-9b13-b1ac7f9b4144/.default'
export const APP_D365_PATH = 'https://unitedmalt.operations.dynamics.com/'
export const APP_ENV_THEME_PRESET = 'prod';
export const APP_ENV_HEADER_LABEL = '';

export const ENV = "prod"; // "debug"; //"prod";

//Use this to print to console only when in debug mode.
export const Trace = (msg) => {
    if (ENV === "debug") {
        console.log(msg);
    }
};

export const FUMES_VERSION = 'v2.0.0'

const config = {
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    //presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6 --- Production
    //presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6  --- SPT
    presetColor: APP_ENV_THEME_PRESET,
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false
};

export default config;
