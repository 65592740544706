/**
 * axios setup to use mock service
 */

import axios from 'axios';
import { useContext } from 'react';
import AzureMSALContext from 'contexts/AzureMSALContext';

const axiosServices = axios.create();

// Add a request interceptor
axiosServices.interceptors.request.use(
    async (config) => {
        const currentTime = Date.now();

        const token = sessionStorage.getItem('access_token');
        const token_expiry = sessionStorage.getItem('token_expiry');

        const tokenExpiration = new Date(token_expiry).getTime();

        if (token !== "" && token !== null && token !== undefined &&
            tokenExpiration !== 0 && token_expiry && currentTime < tokenExpiration) {
            const token = sessionStorage.getItem('access_token');
            config.headers.Authorization = `Bearer ${token}`;
        } else {
            try {
                const { getToken } = useContext(AzureMSALContext);
                const newToken = await getToken(); // call the getToken function
                config.headers.Authorization = `Bearer ${newToken}`;
            } catch (error) {
                // handle the error
            }
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Add a response interceptor
axiosServices.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            await new Promise(resolve => setTimeout(resolve, 1000)); // wait for 1000ms before retrying the request
            const token = sessionStorage.getItem('access_token');
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return axiosServices(originalRequest);
        }

        return Promise.reject(error);
    }
);


export default axiosServices;