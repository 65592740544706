import dashboard from './dashboard';
import recipes from './recipes';
import tools from './tools';
import config from './config';

// ==============================|| MENU ITEMS ||============================== //

export const menuItems = {
    items: [dashboard, recipes] //, tools]
};

export const menuItemsAdmin = {
    items: [dashboard, recipes, tools, config]
};

export default menuItems;
