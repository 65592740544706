import { memo, useContext } from 'react';

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import { menuItems, menuItemsAdmin } from 'menu-items';

import AzureMSALContext from 'contexts/AzureMSALContext';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { isGlobalAdmin } = useContext(AzureMSALContext);
    let menuItem = menuItems;

    if (isGlobalAdmin) {
        menuItem = menuItemsAdmin;
    }

    const navItems = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default memo(MenuList);
