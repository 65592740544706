// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconCalculator } from '@tabler/icons';

// constant
const icons = {
    IconCalculator
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const tools = {
    id: 'tools',
    title: <FormattedMessage id="tools" />,
    type: 'group',
    children: [
        {
            id: 'tool-inventory',
            title: <FormattedMessage id="cycle-count" />,
            type: 'item',
            url: '/tools/cyclecount',
            icon: icons.IconCalculator,
            breadcrumbs: false
        }
    ]
};

export default tools;
