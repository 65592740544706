import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// Recipe Management routing
// Steep
const AppNestedSteep = Loadable(lazy(() => import('views/recipes/Steep')));
const AppSteepFillDrain = Loadable(lazy(() => import('views/recipes/Steep/Steps/StepGrids/RecipeSteepFillDrain')));

// Germ
const AppNestedGerm = Loadable(lazy(() => import('views/recipes/Germ')));
const AppGermAirOff = Loadable(lazy(() => import('views/recipes/Germ/Steps/StepGrids/RecipeGermAirOff')));
const AppGermAirOn = Loadable(lazy(() => import('views/recipes/Germ/Steps/StepGrids/RecipeGermAirOn')));
const AppGermTurn = Loadable(lazy(() => import('views/recipes/Germ/Steps/StepGrids/RecipeGermTurn')));

// Kiln
const AppNestedKiln = Loadable(lazy(() => import('views/recipes/Kiln')));
const AppKilnAirOn = Loadable(lazy(() => import('views/recipes/Kiln/Steps/StepGrids/RecipeKilnAirOn')));
const AppKilnRecirc = Loadable(lazy(() => import('views/recipes/Kiln/Steps/StepGrids/RecipeKilnRecirc')));
const AppKilnTurn = Loadable(lazy(() => import('views/recipes/Kiln/Steps/StepGrids/RecipeKilnTurn')));

// Steep
const AppNestedRoast = Loadable(lazy(() => import('views/recipes/Roast')));
const AppRoastReduct = Loadable(lazy(() => import('views/recipes/Roast/Steps/StepGrids/RecipeRoastReduct')));

//Tools
//Counting
const AppCounting = Loadable(lazy(() => import('views/tools/Counting')));

// Config 
const AppConfigPages = Loadable(lazy(() => import('views/config/CRUD')));
const AppConfigBin = Loadable(lazy(() => import('views/config/Bins')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutesAdmin = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: '/recipes/kiln',
            element: <AppNestedKiln />,
            children: [
                {
                    path: 'AirOn',
                    element: <AppKilnAirOn />
                },
                {
                    path: 'Recirc',
                    element: <AppKilnRecirc />
                },
                {
                    path: 'TurnSchedule',
                    element: <AppKilnTurn />
                }
            ]
        },
        {
            path: '/recipes/steep',
            element: <AppNestedSteep />,
            children: [
                {
                    path: 'FillDrain',
                    element: <AppSteepFillDrain />
                }
            ]
        },
        {
            path: '/recipes/germ',
            element: <AppNestedGerm />,
            children: [
                {
                    path: 'AirOff',
                    element: <AppGermAirOff />
                },
                {
                    path: 'AirOn',
                    element: <AppGermAirOn />
                },
                {
                    path: 'TurnSchedule',
                    element: <AppGermTurn />
                }
            ]
        },
        {
            path: '/recipes/roast',
            element: <AppNestedRoast />,
            children: [
                {
                    path: 'reduct',
                    element: <AppRoastReduct />
                }
            ]
        },
        {
            path: '/tools',
            children: [
                {
                    path: 'cyclecount',
                    element: <AppCounting />
                }
            ]
        },
        {
            path: '/config',
            children: [
                {
                    path: 'productview',
                    element: <AppConfigPages profile={'ProductView'} />,
                },
                {
                    path: 'site',
                    element: <AppConfigPages profile={'Site'} />,
                },
                {
                    path: 'bin',
                    children: [
                        {
                            path: 'site-bin-location',
                            element: <AppConfigPages profile={'SiteBinLocations'} />,
                        },
                        {
                            path: 'co2extract',
                            element: <AppConfigPages profile={'CO2Extract'} />,
                        },
                        {
                            path: 'drain',
                            element: <AppConfigPages profile={'Drain'} />,
                        },
                        {
                            path: 'fill',
                            element: <AppConfigPages profile={'Fill'} />,
                        }
                    ]
                },
                {
                    path: 'malthouse',
                    element: <AppConfigPages profile={'Malthouse'} />,
                },
                {
                    path: 'types',
                    children: [
                        {
                            path: 'aeration',
                            element: <AppConfigPages profile={'Aeration'} />,
                        },
                        {
                            path: 'co2extract',
                            element: <AppConfigPages profile={'CO2Extract'} />,
                        },
                        {
                            path: 'drain',
                            element: <AppConfigPages profile={'Drain'} />,
                        },
                        {
                            path: 'fill',
                            element: <AppConfigPages profile={'Fill'} />,
                        },
                        {
                            path: 'humidify',
                            element: <AppConfigPages profile={'Humidify'} />,
                        },
                        {
                            path: 'overflow',
                            element: <AppConfigPages profile={'Overflow'} />,
                        },
                        {
                            path: 'turn',
                            element: <AppConfigPages profile={'Turn'} />,
                        }
                    ]
                }
            ]
        },
        {
            path: '/',
            element: <DashboardDefault />
        }
    ]
};

export default MainRoutesAdmin;
