// third-party
import { FormattedMessage, useIntl } from 'react-intl';

// assets
import { IconPool, IconSettingsAutomation, IconMap2, IconBuildingWarehouse, IconCylinder } from '@tabler/icons';

// constant
const icons = {
    IconSettingsAutomation,
    IconPool,
    IconMap2,
    IconBuildingWarehouse,
    IconCylinder
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const config = {
    id: 'config',
    title: <FormattedMessage id="configuration" />,
    type: 'group',
    children: [
        {
            id: 'productview-confi',
            title: <FormattedMessage id="productview" />,
            type: 'item',
            url: '/config/productview',
            icon: icons.IconCylinder,
            breadcrumbs: false
        },
        {
            id: 'site-confi',
            title: <FormattedMessage id="site" />,
            type: 'item',
            url: '/config/site',
            icon: icons.IconMap2,
            breadcrumbs: false
        },
        {
            id: 'malthouse-confi',
            title: <FormattedMessage id="malthouse" />,
            type: 'item',
            url: '/config/malthouse',
            icon: icons.IconBuildingWarehouse,
            breadcrumbs: false
        },
        {
            id: 'bin-confi',
            title: <FormattedMessage id="bin" />,
            type: 'collapse',
            url: '/config/bin',
            icon: icons.IconCylinder,
            breadcrumbs: false,
            children: [
                {
                    id: 'site-bin-location',
                    title: <FormattedMessage id="site-bin-location" />,
                    type: 'item',
                    url: '/config/bin/site-bin-location',
                    breadcrumbs: false
                },
                {
                    id: 'bin-type',
                    title: <FormattedMessage id="bin-type" />,
                    type: 'item',
                    url: '/config/bin/type',
                    breadcrumbs: false
                },
                {
                    id: 'bin-role',
                    title: <FormattedMessage id="bin-role" />,
                    type: 'item',
                    url: '/config/bin/role',
                    breadcrumbs: false
                },
                {
                    id: 'bin-shape',
                    title: <FormattedMessage id="bin-shape" />,
                    type: 'item',
                    url: '/config/bin/shape',
                    breadcrumbs: false
                },
            ]
        },
        {
            id: 'type-confi',
            title: <FormattedMessage id="types" />,
            type: 'collapse',
            icon: icons.IconSettingsAutomation,
            children: [
                {
                    id: 'aeration-confi',
                    title: <FormattedMessage id="aeration" />,
                    type: 'item',
                    url: '/config/types/aeration',
                    breadcrumbs: false
                },
                {
                    id: 'co2extract-confi',
                    title: <FormattedMessage id="co2-extract" />,
                    type: 'item',
                    url: '/config/types/co2extract',
                    breadcrumbs: false
                },
                {
                    id: 'drain-confi',
                    title: <FormattedMessage id="drain" />,
                    type: 'item',
                    url: '/config/types/drain',
                    breadcrumbs: false
                },
                {
                    id: 'fill-confi',
                    title: <FormattedMessage id="fill" />,
                    type: 'item',
                    url: '/config/types/fill',
                    breadcrumbs: false
                },
                {
                    id: 'humidify-confi',
                    title: <FormattedMessage id="humidify" />,
                    type: 'item',
                    url: '/config/types/humidify',
                    breadcrumbs: false
                },
                {
                    id: 'overflow-confi',
                    title: <FormattedMessage id="overflow" />,
                    type: 'item',
                    url: '/config/types/overflow',
                    breadcrumbs: false
                },
                {
                    id: 'turn-confi',
                    title: <FormattedMessage id="turn" />,
                    type: 'item',
                    url: '/config/types/turn',
                    breadcrumbs: false
                },
            ]
        }
    ]
};

export default config;
