import { lazy } from 'react';

// project imports
import MinimalLayout from 'layout/MinimalLayout';

import Loadable from 'ui-component/Loadable';

const Auth = Loadable(lazy(() => import('views/pages/authentication/Auth')));
// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/auth',
    element: <Auth />
};

export default AuthenticationRoutes;
