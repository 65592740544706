// third-party
import { ContentPasteSearchOutlined } from '@mui/icons-material';
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { APP_API_URL } from '../../config';

// ----------------------------------------------------------------------
const apiURL = APP_API_URL;

const initialState = {
    error: null,
    kilnId: 0,
    kilnRow: null,
    recipes: [],
    airOnSteps: [],
    recircSteps: [],
    turnSteps: [],
    toggleSteps: false
};

const slice = createSlice({
    name: 'recipe',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // Get Recipe
        getRecipeSuccess(state, action) {
            state.recipes = action.payload;
        },

        // Get Air On Steps
        getRecipeAirOnSuccess(state, action) {
            state.airOnSteps = action.payload;
        },

        // Get Recirc Steps
        getRecipeRecircSuccess(state, action) {
            state.recircSteps = action.payload;
        },

        // Get Turn Steps
        getRecipeTurnSuccess(state, action) {
            state.turnSteps = action.payload;
        },

        // Set KilnId
        storeKilnId(state, action) {
            state.kilnId = action.payload;
        },

        // Set KilnId
        storeKilnRow(state, action) {
            state.kilnRow = action.payload;
        },

        // Toggle Steps
        toggleStep(state, action) {
            state.toggleSteps = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function setKilnId(id) {
    dispatch(slice.actions.storeKilnId(id));
}

export function setKilnSelectedRow(kilnRow) {
    dispatch(slice.actions.storeKilnRow(kilnRow));
}

export function setToggle(ShowSteps) {
    dispatch(slice.actions.toggleStep(ShowSteps));
}

export function getKilnRecipes() {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/recipes/kiln`);
            dispatch(slice.actions.getRecipeSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getKilnRecipesBySite(SiteId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/recipes/kiln/all/site/${SiteId}`);
            dispatch(slice.actions.getRecipeSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getKilnRecipesByRoute(RouteNumber) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/recipes/kiln/all/route/${RouteNumber}`);
            dispatch(slice.actions.getRecipeSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getKilnRecipesByMalthouse(siteId, maltHouseId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/recipes/kiln/all/${siteId}/${maltHouseId}`);
            dispatch(slice.actions.getRecipeSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function duplicateKilnRecipe(KilnId, newRecipe) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/data/kiln/${KilnId}/copy`,
                data: JSON.stringify(newRecipe),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log(`Duplicate Kiln:${KilnId}`);
                dispatch(getKilnRecipes());
                console.log('Get Kiln list');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createKilnRecipe(newRecord, siteId = 0) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/recipes/kiln`,
                data: JSON.stringify(newRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('created');
                if (siteId === 0) {
                    dispatch(getKilnRecipes());
                } else {
                    dispatch(getKilnRecipesBySite(siteId));
                }
                console.log('get kiln list');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editKilnRecipe(updatedRecord, KilnId, siteId = 0) {
    return async () => {
        try {
            const response = await axios({
                method: 'PUT',
                url: `${apiURL}/recipes/kiln/${KilnId}`,
                data: JSON.stringify(updatedRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Updated');
                if (siteId === 0) {
                    dispatch(getKilnRecipes());
                } else {
                    dispatch(getKilnRecipesBySite(siteId));
                }
                console.log('Get kiln list');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteKilnRecipe(KilnId, siteId = 0) {
    return async () => {
        try {
            const response = await axios.delete(`${apiURL}/recipes/kiln/${KilnId}`).then((response) => {
                console.log('Deleted');
                if (siteId === 0) {
                    dispatch(getKilnRecipes());
                } else {
                    dispatch(getKilnRecipesBySite(siteId));
                }
                console.log('Done Delete');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editKilnStep(updatedRecord, KilnId, StepNumber, ScheduleType) {
    return async () => {
        try {
            const response = await axios({
                method: 'PUT',
                url: `${apiURL}/recipes/kiln/${KilnId}/${ScheduleType}/${StepNumber}`,
                data: JSON.stringify(updatedRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Updated');
                switch (ScheduleType) {
                    case 'airon':
                        dispatch(getKilnAirOnStepsById(KilnId));
                        break;
                    case 'recirc':
                        dispatch(getKilnRecircById(KilnId));
                        break;
                    case 'turn':
                        dispatch(getKilnTurnById(KilnId));
                        break;
                }
                console.log('Get Kiln list');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// -----------------------------------------------------
// Air ON
export function getKilnAirOnStepsById(KilnId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/recipes/kiln/${KilnId}/airon/`);
            dispatch(slice.actions.getRecipeAirOnSuccess(response.data));
            console.log('Get kilns');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteKilnAirOnStep(KilnId, StepNumber) {
    return async () => {
        try {
            const response = await axios
                .delete(`${apiURL}/recipes/kiln/${KilnId}/airon/${StepNumber}`)
                .then((response) => {
                    console.log('Deleted');
                    dispatch(getKilnAirOnStepsById(KilnId));
                    console.log('Done Delete');
                });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createKilnAirOnStep(KilnId, newRecord) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/recipes/kiln/${KilnId}/airon`,
                data: JSON.stringify(newRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('created');
                dispatch(getKilnAirOnStepsById(KilnId));
                console.log('get Air On Steps List');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function toggleArchiveKilnAirOnStep(KilnId, StepNumber, Archive) {
    // TODO: Change to archived column
    const body = [
        {
            path: 'archived',
            op: 'add',
            value: Archive
        }
    ];
    return async () => {
        try {
            const response = await axios({
                method: 'PATCH',
                url: `${apiURL}/recipes/kiln/${KilnId}/airon/${StepNumber}`,
                data: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Archived');
                dispatch(getKilnAirOnStepsById(KilnId));
                console.log('Done archive');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// -----------------------------------------------------
// Recirc
export function getKilnRecircById(KilnId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/recipes/kiln/${KilnId}/recirc`);
            dispatch(slice.actions.getRecipeRecircSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteKilnRecircStep(KilnId, StepNumber) {
    return async () => {
        try {
            const response = await axios
                .delete(`${apiURL}/recipes/kiln/${KilnId}/recirc/${StepNumber}`)
                .then((response) => {
                    console.log('Deleted');
                    dispatch(getKilnRecircById(KilnId));
                    console.log('Done Delete');
                });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createKilnRecircStep(KilnId, newRecord) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/recipes/kiln/${KilnId}/recirc`,
                data: JSON.stringify(newRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('created');
                dispatch(getKilnRecircById(KilnId));
                console.log('get Turn Steps List');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// Archive Rows
export function toggleArchiveRecircStep(KilnId, StepNumber, Archive) {
    // TODO: Change to archived column
    const body = [
        {
            path: 'archived',
            op: 'add',
            value: Archive
        }
    ];
    return async () => {
        try {
            const response = await axios({
                method: 'PATCH',
                url: `${apiURL}/recipes/kiln/${KilnId}/recirc/${StepNumber}`,
                data: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Archived');
                dispatch(getKilnRecircById(KilnId));
                console.log('Done archive');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// -----------------------------------------------------
// Turn
export function getKilnTurnById(KilnId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/recipes/kiln/${KilnId}/turn`);
            dispatch(slice.actions.getRecipeTurnSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteKilnTurnStep(KilnId, StepNumber) {
    return async () => {
        try {
            const response = await axios.delete(`${apiURL}/recipes/kiln/${KilnId}/turn/${StepNumber}`).then((response) => {
                console.log('Deleted');
                dispatch(getKilnTurnById(KilnId));
                console.log('Done Delete');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createKilnTurnStep(KilnId, newRecord) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/recipes/kiln/${KilnId}/turn`,
                data: JSON.stringify(newRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('created');
                dispatch(getKilnTurnById(KilnId));
                console.log('get Turn Steps List');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function toggleArchiveTurnStep(KilnId, StepNumber, Archive) {
    // TODO: Change to archived column
    const body = [
        {
            path: 'archived',
            op: 'add',
            value: Archive
        }
    ];
    return async () => {
        try {
            const response = await axios({
                method: 'PATCH',
                url: `${apiURL}/recipes/kiln/${KilnId}/turn/${StepNumber}`,
                data: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Archived');
                dispatch(getKilnTurnById(KilnId));
                console.log('Done archive');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
