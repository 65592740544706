// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { APP_API_URL } from '../../config';

// ----------------------------------------------------------------------
const apiURL = APP_API_URL;

const initialState = {
    error: null,
    prodOrderList: [],
    prodOrderProducts: [],
    prodOrderPickList: [],
    heartBeatStatus: null,
    prodOrder: null,
    steep: null,
    germ: null,
    kiln: null,
    roast: null,
    processing: false,
};

const slice = createSlice({
    name: 'prodorder',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // Get Prod Orders
        getProdOrdersSuccess(state, action) {
            state.prodOrderList = action.payload;
        },

        // Get Prod Single Prod Order
        getSingleOrderSuccess(state, action) {
            state.prodOrder = action.payload;
        },

        // Get Prod Steep
        getSteepSuccess(state, action) {
            state.steep = action.payload;
        },

        // Get Prod Germ
        getGermSuccess(state, action) {
            state.germ = action.payload;
        },

        // Get Prod Single Prod Order
        getKilnSuccess(state, action) {
            state.kiln = action.payload;
        },

        // Get Prod Single Prod Order
        getRoastSuccess(state, action) {
            state.roast = action.payload;
        },

        getProdOrderProductsSuccess(state, action) {
            state.prodOrderProducts = action.payload;
        },

        getProdOrderPickListSuccess(state, action) {
            state.prodOrderPickList = action.payload;
        },

        getHeartBeatSuccess(state, action) {
            state.heartBeatStatus = action.payload;
        },

        clearStore(state, action) {
            state.kiln = action.payload;
            state.germ = action.payload;
            state.steep = action.payload;
            state.roast = action.payload;
            state.prodOrder = action.payload;
            state.prodOrderProducts = action.payload;
            state.prodOrderPickList = action.payload;
        },
        clearHearbeat(state, action) {
            state.heartBeatStatus = action.payload
        },
        setProcessing(state, action) {
            state.processing = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

export function clearProdOrderStore(emptyVal) {
    dispatch(slice.actions.clearStore(emptyVal));
}

export function clearHeartbeatStatus(emptyVal) {
    dispatch(slice.actions.clearHearbeat(emptyVal));
}

export function setPickProcess(processing){
    dispatch(slice.actions.setProcessing(processing));
}

// ----------------------------------------------------------------------
export function getProdOrders() {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/prodorder`);
            dispatch(slice.actions.getProdOrdersSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getSiteProdOrders(SiteId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/prodorder/all/${SiteId}`);
            dispatch(slice.actions.getProdOrdersSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getRouteTypeProdOrders(SiteId, RouteType) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/prodorder/all/${SiteId}/${RouteType}`);
            dispatch(slice.actions.getProdOrdersSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getRouteProdOrders(SiteId, RouteType, Route) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/prodorder/all/${SiteId}/${RouteType}/${Route}`);
            dispatch(slice.actions.getProdOrdersSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function getSingleProdOrder(ProdOrderId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/prodorder/${ProdOrderId}`);
            dispatch(slice.actions.getSingleOrderSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function startFUMESManually(ProdOrderId) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/prodorder/startfumesmanually`,
                data: JSON.stringify({
                    prodOrderId: ProdOrderId
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log(`Started Produciton Order :${ProdOrderId} in FUMES Manually`);
                dispatch(getSingleProdOrder(ProdOrderId));
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function markCompleted(ProdOrderId) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/prodorder/markcompleted`,
                data: JSON.stringify({
                    prodOrderId: ProdOrderId
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log(`Marked Produciton Order :${ProdOrderId} completed`);
                dispatch(getSingleProdOrder(ProdOrderId));
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function postStartProductionOrder(ProdOrderId) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/prodorder/poststart`,
                data: JSON.stringify({
                    prodOrderId: ProdOrderId
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log(`Started Production :${ProdOrderId}`);
                dispatch(getSingleProdOrder(ProdOrderId));
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteOrder(ProdOrderId) {
    return async () => {
        try {
            const reponse = await axios({
                method: 'POST',
                url: `${apiURL}/prodorder/deleteorder`,
                data: JSON.stringify({
                    prodOrderId: ProdOrderId
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log(`Deleted Production Order :${ProdOrderId}`);
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function postPickList(pickList) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/prodorder/postpicklist`,
                data: JSON.stringify(pickList),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log(`pick list sent:${pickList.ProdOrderId}`);
                dispatch(getProdOrderPickList(pickList.ProdOrderId));
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function jobCardProductionOrder(ProdOrderId) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/prodorder/postjobcard`,
                data: JSON.stringify({
                    prodOrderId: ProdOrderId
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log(`Start Production :${ProdOrderId}`);
                dispatch(getSingleProdOrder(ProdOrderId));
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function postRAF(ProdOrderId, Event, ProductId = 0, postDate = null) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/prodorder/postraf`,
                data: JSON.stringify({
                    prodOrderId: ProdOrderId,
                    offKilnProductId: ProductId,
                    event: Event,
                    postDate: postDate ? postDate.format('MMMM D, YYYY 12:00') : null
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log(`Start Production :${ProdOrderId}`);
                dispatch(getSingleProdOrder(ProdOrderId));
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteRAFList(rafList) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/prodorder/deleteraflist`,
                data: JSON.stringify(rafList),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log(`raf list deleted:${rafList.ProdOrderId}`);
                dispatch(getProdOrderProducts(rafList.ProdOrderId));
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function resetRAF(ProdOrderId) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/prodorder/resetraf`,
                data: JSON.stringify({
                    prodOrderId: ProdOrderId
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log(`Started Production :${ProdOrderId}`);
                dispatch(getSingleProdOrder(ProdOrderId));
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function resetPick(ProdOrderId) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/prodorder/resetpick`,
                data: JSON.stringify({
                    prodOrderId: ProdOrderId
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log(`Reset Pick List :${ProdOrderId}`);
                dispatch(getProdOrderPickList(ProdOrderId));
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function reOpenProductionOrder(ProdOrderId) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/prodorder/postreopen`,
                data: JSON.stringify({
                    prodOrderId: ProdOrderId
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log(`Production Order Started:${ProdOrderId}`);
                dispatch(getSingleProdOrder(ProdOrderId));
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function setProductionPool(ProdOrderId, PoolValue) {
    // TODO: Change to archived column
    const body = [
        {
            path: 'productionpool',
            op: 'add',
            value: PoolValue
        }
    ];
    return async () => {
        try {
            const response = await axios({
                method: 'PATCH',
                url: `${apiURL}/prodorder/${ProdOrderId}/updatepool`,
                data: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Pool Updated');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateConsumption(ProdOrderId, ItemCode, WeightConsumed) {
    const body = [
        {
            path: 'fumesconsumed',
            op: 'add',
            value: WeightConsumed
        }
    ];
    return async () => {
        try {
            const response = await axios({
                method: 'PATCH',
                url: `${apiURL}/prodorder/${ProdOrderId}/pick/${ItemCode}/updateconsumed`,
                data: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Consumption Updated');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateProductQty(ProdOrderId, ItemId, Qty) {
    const body = [
        {
            path: 'qty',
            op: 'add',
            value: Qty
        }
    ];
    return async () => {
        try {
            const response = await axios({
                method: 'PATCH',
                url: `${apiURL}/prodorder/${ProdOrderId}/product/${ItemId}/updateqty`,
                data: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Qty Updated');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateProdOrderLocation(ProdOrderId, CurrentLocation) {

    try {
        const response = axios({
            method: 'PATCH',
            url: `${apiURL}/prodorder/${ProdOrderId}/updatelocation/${CurrentLocation}`,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            console.log('CurrentLocation Updated');
        });
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }

}

export function updateBlendProductionOrder(ProdOrderId, UpdateBody) {
    return async () => {
        try {
            const response = await axios({
                method: 'PUT',
                url: `${apiURL}/prodorder/${ProdOrderId}/blend`,
                data: JSON.stringify(UpdateBody),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Blending Production Order Updated');
                dispatch(getSingleProdOrder(ProdOrderId));
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateCleanProductionOrder(ProdOrderId, UpdateBody) {
    return async () => {
        try {
            const response = await axios({
                method: 'PUT',
                url: `${apiURL}/prodorder/${ProdOrderId}/clean`,
                data: JSON.stringify(UpdateBody),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Cleaning Production Order Updated');
                dispatch(getSingleProdOrder(ProdOrderId));
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateBatchProductionOrder(ProdOrderId, UpdateBody) {
    return async () => {
        try {
            const response = await axios({
                method: 'PUT',
                url: `${apiURL}/prodorder/${ProdOrderId}/batch`,
                data: JSON.stringify(UpdateBody),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Batch Production Order Updated');
                dispatch(getSingleProdOrder(ProdOrderId));
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updatePickListItems(ProdOrderId, UpdateBody) {
    return async () => {
        try {
            const response = await axios({
                method: 'PUT',
                url: `${apiURL}/prodorder/${ProdOrderId}/picklist`,
                data: JSON.stringify(UpdateBody),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Pick List Updated');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProdOrderPickList(ProdOrderId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/prodorder/${ProdOrderId}/picklist`);
            dispatch(slice.actions.getProdOrderPickListSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createPickItems(ProdOrderId, NewPickItems) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/prodorder/${ProdOrderId}/picklist`,
                data: JSON.stringify(NewPickItems),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('New Pick Items Created');
                dispatch(slice.actions.getProdOrderPickListSuccess(response.data));
                setPickProcess(false);
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deletePickList(pickList) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/prodorder/deletpicklist`,
                data: JSON.stringify(pickList),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log(`pick list deleted:${pickList.ProdOrderId}`);
                dispatch(getProdOrderPickList(pickList.ProdOrderId));
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function getProdOrderProducts(ProdOrderId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/prodorder/${ProdOrderId}/productlist`);
            dispatch(slice.actions.getProdOrderProductsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createProductItems(ProdOrderId, NewProducts) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/prodorder/${ProdOrderId}/productlist`,
                data: JSON.stringify(NewProducts),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Products Created');
                dispatch(getProdOrderProducts(ProdOrderId));
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateProductItems(ProdOrderId, UpdateBody) {
    return async () => {
        try {
            const response = await axios({
                method: 'PUT',
                url: `${apiURL}/prodorder/${ProdOrderId}/productlist`,
                data: JSON.stringify(UpdateBody),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Products Updated');
                dispatch(getProdOrderProducts(ProdOrderId));
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getHeartBeat(ProdOrderId) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/prodorder/heartbeat`,
                data: JSON.stringify({
                    prodOrderId: ProdOrderId
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                dispatch(slice.actions.getHeartBeatSuccess(response.data));
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

//-------------------Generic --------------------------
export function editProdOrderStep(ProdOrderId, updatedRecord, RecipeId, StepNumber, ScheduleType, recipeType) {
    return async () => {
        try {
            const response = await axios({
                method: 'PUT',
                url: `${apiURL}/prodorder/${ProdOrderId}/recipes/${recipeType}/${RecipeId}/${ScheduleType}/${StepNumber}`,
                data: JSON.stringify(updatedRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Updated');
                switch (recipeType) {
                    case 'steep':
                        dispatch(getSteepWSteepId(ProdOrderId, RecipeId));
                        break;
                    case 'germ':
                        dispatch(getGermWGermId(ProdOrderId, RecipeId));
                        break;
                    case 'kiln':
                        dispatch(getKilnWKilnId(ProdOrderId, RecipeId));
                        break;
                }
                console.log('Get Steep list');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


// -----------------------------------------------------
// ------------------Steep
export function getSteepWSteepId(ProdOrderId, SteepId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/prodorder/${ProdOrderId}/recipes/steep/${SteepId}/wsteps`);
            dispatch(slice.actions.getSteepSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getSteep(ProdOrderId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/prodorder/${ProdOrderId}/recipes/steep`);
            dispatch(slice.actions.getSteepSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createProdOrderSteep(ProdOrderId, newRecord) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/prodorder/${ProdOrderId}/recipes/steep/`,
                data: JSON.stringify(newRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Created ProdOrder Recipe');
                dispatch(getSteep(ProdOrderId));
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

// Fill Drain
export function deleteProdorderSteepFillDrainStep(ProdOrderId, SteepId, StepNumber) {
    return async () => {
        try {
            const response = await axios
                .delete(`${apiURL}/prodorder/${ProdOrderId}/recipes/steep/${SteepId}/filldrain/${StepNumber}`)
                .then((response) => {
                    console.log('Deleted');
                    dispatch(getSteepWSteepId(ProdOrderId, SteepId));
                    console.log('Get Steep');
                });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createProdOrderSteepFillDrainStep(ProdOrderId, SteepId, newRecord) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/prodorder/${ProdOrderId}/recipes/steep/${SteepId}/filldrain`,
                data: JSON.stringify(newRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('created');
                dispatch(getSteepWSteepId(ProdOrderId, SteepId));
                console.log('Get Steep');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// -----------------------------------------------------
// ------------------Germ
export function getGerm(ProdOrderId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/prodorder/${ProdOrderId}/recipes/germ`);
            dispatch(slice.actions.getGermSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getGermWGermId(ProdOrderId, GermId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/prodorder/${ProdOrderId}/recipes/germ/${GermId}/wsteps`);
            dispatch(slice.actions.getGermSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createProdOrderGerm(ProdOrderId, newRecord) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/prodorder/${ProdOrderId}/recipes/germ/`,
                data: JSON.stringify(newRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Created ProdOrder Recipe');
                dispatch(getGerm(ProdOrderId));
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

//Germ - Air On
export function deleteProdOrderGermAirOnStep(ProdOrderId, GermId, StepNumber) {
    return async () => {
        try {
            const response = await axios
                .delete(`${apiURL}/prodorder/${ProdOrderId}/recipes/germ/${GermId}/airon/${StepNumber}`)
                .then((response) => {
                    console.log('Deleted');
                    dispatch(getGermWGermId(ProdOrderId, GermId));
                    console.log('Get Germ');
                });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createProdOrderGermAirOnStep(ProdOrderId, GermId, newRecord) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/prodorder/${ProdOrderId}/recipes/germ/${GermId}/airon`,
                data: JSON.stringify(newRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('created');
                dispatch(getGermWGermId(ProdOrderId, GermId));
                console.log('Get Germ');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

//Germ -Air off
export function deleteProdOrderGermAirOffStep(ProdOrderId, GermId, StepNumber) {
    return async () => {
        try {
            const response = await axios
                .delete(`${apiURL}/prodorder/${ProdOrderId}/recipes/germ/${GermId}/airoff/${StepNumber}`)
                .then((response) => {
                    console.log('Deleted');
                    dispatch(getGermWGermId(ProdOrderId, GermId));
                    console.log('Get Germ');
                });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createProdOrderGermAirOffStep(ProdOrderId, GermId, newRecord) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/prodorder/${ProdOrderId}/recipes/germ/${GermId}/airoff`,
                data: JSON.stringify(newRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('created');
                dispatch(getGermWGermId(ProdOrderId, GermId));
                console.log('Get Germ');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

//Germ - Turn
export function deleteProdOrderGermTurnStep(ProdOrderId, GermId, StepNumber) {
    return async () => {
        try {
            const response = await axios
                .delete(`${apiURL}/prodorder/${ProdOrderId}/recipes/germ/${GermId}/turn/${StepNumber}`)
                .then((response) => {
                    console.log('Deleted');
                    dispatch(getGermWGermId(ProdOrderId, GermId));
                    console.log('Get Germ');
                });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createProdOrderGermTurnStep(ProdOrderId, GermId, newRecord) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/prodorder/${ProdOrderId}/recipes/germ/${GermId}/turn`,
                data: JSON.stringify(newRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('created');
                dispatch(getGermWGermId(ProdOrderId, GermId));
                console.log('Get Germ');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// -----------------------------------------------------
// ------------------Kiln
export function getKiln(ProdOrderId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/prodorder/${ProdOrderId}/recipes/kiln`);
            dispatch(slice.actions.getKilnSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getKilnWKilnId(ProdOrderId, KilnId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/prodorder/${ProdOrderId}/recipes/kiln/${KilnId}/wsteps`);
            dispatch(slice.actions.getKilnSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createProdOrderKiln(ProdOrderId, newRecord) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/prodorder/${ProdOrderId}/recipes/kiln/`,
                data: JSON.stringify(newRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Created ProdOrder Recipe');
                dispatch(getKiln(ProdOrderId));
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

//Kiln - Air On
export function deleteProdOrderKilnAirOnStep(ProdOrderId, KilnId, StepNumber) {
    return async () => {
        try {
            const response = await axios
                .delete(`${apiURL}/prodorder/${ProdOrderId}/recipes/kiln/${KilnId}/airon/${StepNumber}`)
                .then((response) => {
                    console.log('Deleted');
                    dispatch(getKilnWKilnId(ProdOrderId, KilnId));
                    console.log('Get Kiln');
                });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createProdOrderKilnAirOnStep(ProdOrderId, KilnId, newRecord) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/prodorder/${ProdOrderId}/recipes/kiln/${KilnId}/airon`,
                data: JSON.stringify(newRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('created');
                dispatch(getKilnWKilnId(ProdOrderId, KilnId));
                console.log('Get Kiln');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

//Kiln - Recirc
export function deleteProdOrderKilnRecircStep(ProdOrderId, KilnId, StepNumber) {
    return async () => {
        try {
            const response = await axios
                .delete(`${apiURL}/prodorder/${ProdOrderId}/recipes/kiln/${KilnId}/recirc/${StepNumber}`)
                .then((response) => {
                    console.log('Deleted');
                    dispatch(getKilnWKilnId(ProdOrderId, KilnId));
                    console.log('Get Kiln');
                });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createProdOrderKilnRecircStep(ProdOrderId, KilnId, newRecord) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/prodorder/${ProdOrderId}/recipes/kiln/${KilnId}/recirc`,
                data: JSON.stringify(newRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('created');
                dispatch(getKilnWKilnId(ProdOrderId, KilnId));
                console.log('Get Kiln');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

//Kiln - Turn
export function deleteProdOrderKilnTurnStep(ProdOrderId, KilnId, StepNumber) {
    return async () => {
        try {
            const response = await axios
                .delete(`${apiURL}/prodorder/${ProdOrderId}/recipes/kiln/${KilnId}/turn/${StepNumber}`)
                .then((response) => {
                    console.log('Deleted');
                    dispatch(getKilnWKilnId(ProdOrderId, KilnId));
                    console.log('Get Kiln');
                });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createProdOrderKilnTurnStep(ProdOrderId, KilnId, newRecord) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/prodorder/${ProdOrderId}/recipes/kiln/${KilnId}/turn`,
                data: JSON.stringify(newRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('created');
                dispatch(getKilnWKilnId(ProdOrderId, KilnId));
                console.log('Get Kiln');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// -----------------------------------------------------
// ------------------Roast
export function getRoast(ProdOrderId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/prodorder/${ProdOrderId}/recipes/roast`);
            dispatch(slice.actions.getRoastSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getRoastWRoastId(ProdOrderId, RoastId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/prodorder/${ProdOrderId}/recipes/roast/${RoastId}/wsteps`);
            dispatch(slice.actions.getRoastSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createProdOrderRoast(ProdOrderId, newRecord) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/prodorder/${ProdOrderId}/recipes/roast/`,
                data: JSON.stringify(newRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Created ProdOrder Recipe');
                dispatch(getRoast(ProdOrderId));
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

// Reduct Drain
export function deleteProdorderRoastReductStep(ProdOrderId, RoastId, StepNumber) {
    return async () => {
        try {
            const response = await axios
                .delete(`${apiURL}/prodorder/${ProdOrderId}/recipes/roast/${RoastId}/reduct/${StepNumber}`)
                .then((response) => {
                    console.log('Deleted');
                    dispatch(getRoastWRoastId(ProdOrderId, RoastId));
                    console.log('Get Roast');
                });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createProdOrderRoastReductStep(ProdOrderId, RoastId, newRecord) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/prodorder/${ProdOrderId}/recipes/roast/${RoastId}/reduct`,
                data: JSON.stringify(newRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('created');
                dispatch(getRoastWRoastId(ProdOrderId, RoastId));
                console.log('Get Roast');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}