// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { APP_API_URL } from '../../config';

const apiURL = APP_API_URL;

const initialState = {
    error: null,
    germId: 0,
    germRow: null,
    recipes: [],
    airOnSteps: [],
    airOffSteps: [],
    turnSteps: []
};

const slice = createSlice({
    name: 'recipe',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        // Get Recipe
        getRecipeSuccess(state, action) {
            state.recipes = action.payload;
        },
        // Set GermId
        storeGermId(state, action) {
            state.germId = action.payload;
        },
        // Set GermId
        storeGermRow(state, action) {
            state.germRow = action.payload;
        },
        // Get Air On Steps
        getRecipeAirOnSuccess(state, action) {
            state.airOnSteps = action.payload;
        },
        // Get Air Off Steps
        getRecipeAirOffSuccess(state, action) {
            state.airOffSteps = action.payload;
        },
        // Get Turn Steps
        getRecipeTurnSuccess(state, action) {
            state.turnSteps = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function setGermId(id) {
    dispatch(slice.actions.storeGermId(id));
}

export function setGermSelectedRow(germRow) {
    dispatch(slice.actions.storeGermRow(germRow));
}

export function getGermRecipes() {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/recipes/germ`);
            dispatch(slice.actions.getRecipeSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getGermRecipesBySite(SiteId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/recipes/germ/all/site/${SiteId}`);
            dispatch(slice.actions.getRecipeSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getGermRecipesByRoute(RouteNumber) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/recipes/germ/all/route/${RouteNumber}`);
            dispatch(slice.actions.getRecipeSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getGermRecipesByMalthouse(siteId, maltHouseId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/recipes/germ/all/${siteId}/${maltHouseId}`);
            dispatch(slice.actions.getRecipeSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function duplicateGermRecipe(GermId, newRecipe) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/data/germ/${GermId}/copy`,
                data: JSON.stringify(newRecipe),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log(`Duplicate Germ:${GermId}`);
                dispatch(getGermRecipes());
                console.log('Get Germ list');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createGermRecipe(newRecord, siteId = 0) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/recipes/germ`,
                data: JSON.stringify(newRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('created');
                if (siteId === 0) {
                    dispatch(getGermRecipes());
                } else {
                    dispatch(getGermRecipesBySite(siteId));
                }
                console.log('get germ list');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editGermRecipe(updatedRecord, GermId, siteId = 0) {
    return async () => {
        try {
            const response = await axios({
                method: 'PUT',
                url: `${apiURL}/recipes/germ/${GermId}`,
                data: JSON.stringify(updatedRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Updated');
                if (siteId === 0) {
                    dispatch(getGermRecipes());
                } else {
                    dispatch(getGermRecipesBySite(siteId));
                }
                console.log('Get germ list');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteGermRecipe(GermId, siteId = 0) {
    return async () => {
        try {
            const response = await axios.delete(`${apiURL}/recipes/germ/${GermId}`).then((response) => {
                console.log('Deleted');
                if (siteId === 0) {
                    dispatch(getGermRecipes());
                } else {
                    dispatch(getGermRecipesBySite(siteId));
                }
                console.log('Done Delete');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editGermStep(updatedRecord, GermId, StepNumber, ScheduleType) {
    return async () => {
        try {
            const response = await axios({
                method: 'PUT',
                url: `${apiURL}/recipes/germ/${GermId}/${ScheduleType}/${StepNumber}`,
                data: JSON.stringify(updatedRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Updated');
                switch (ScheduleType) {
                    case 'airon':
                        dispatch(getGermAirOnStepsById(GermId));
                        break;
                    case 'airoff':
                        dispatch(getGermAirOffStepsById(GermId));
                        break;
                    case 'turn':
                        dispatch(getGermTurnById(GermId));
                        break;
                }
                console.log('Get germ list');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// Air ON
export function getGermAirOnStepsById(GermId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/recipes/germ/${GermId}/airon/`);
            dispatch(slice.actions.getRecipeAirOnSuccess(response.data));
            console.log('Get Germs');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteGermAirOnStep(GermId, StepNumber) {
    return async () => {
        try {
            const response = await axios
                .delete(`${apiURL}/recipes/germ/${GermId}/airon/${StepNumber}`)
                .then((response) => {
                    console.log('Deleted');
                    dispatch(getGermAirOnStepsById(GermId));
                    console.log('Done Delete');
                });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createGermAirOnStep(GermId, newRecord) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/recipes/germ/${GermId}/airon`,
                data: JSON.stringify(newRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('created');
                dispatch(getGermAirOnStepsById(GermId));
                console.log('get Air On Steps List');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function toggleArchiveAirOnStep(GermId, StepNumber, Archive) {
    // TODO: Change to archived column
    const body = [
        {
            path: 'archived',
            op: 'add',
            value: Archive
        }
    ];
    return async () => {
        try {
            const response = await axios({
                method: 'PATCH',
                url: `${apiURL}/recipes/germ/${GermId}/airon/${StepNumber}`,
                data: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Archived');
                dispatch(getGermAirOnStepsById(GermId));
                console.log('Done archive');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// Air Off
export function getGermAirOffStepsById(GermId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/recipes/germ/${GermId}/airoff/`);
            dispatch(slice.actions.getRecipeAirOffSuccess(response.data));
            console.log('Get Germs');
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteGermAirOffStep(GermId, StepNumber) {
    return async () => {
        try {
            const response = await axios
                .delete(`${apiURL}/recipes/germ/${GermId}/airoff/${StepNumber}`)
                .then((response) => {
                    console.log('Deleted');
                    dispatch(getGermAirOffStepsById(GermId));
                    console.log('Done Delete');
                });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createGermAirOffStep(GermId, newRecord) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/recipes/germ/${GermId}/airoff`,
                data: JSON.stringify(newRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('created');
                dispatch(getGermAirOffStepsById(GermId));
                console.log('get Air Off Steps List');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function toggleArchiveAirOffStep(GermId, StepNumber, Archive) {
    // TODO: Change to archived column
    const body = [
        {
            path: 'archived',
            op: 'add',
            value: Archive
        }
    ];
    return async () => {
        try {
            const response = await axios({
                method: 'PATCH',
                url: `${apiURL}/recipes/germ/${GermId}/airoff/${StepNumber}`,
                data: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Archived');
                dispatch(getGermAirOffStepsById(GermId));
                console.log('Done archive');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// -----------------------------------------------------
// Turn
export function getGermTurnById(GermId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/recipes/germ/${GermId}/turn`);
            dispatch(slice.actions.getRecipeTurnSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteGermTurnStep(GermId, StepNumber) {
    return async () => {
        try {
            const response = await axios.delete(`${apiURL}/recipes/germ/${GermId}/turn/${StepNumber}`).then((response) => {
                console.log('Deleted');
                dispatch(getGermTurnById(GermId));
                console.log('Done Delete');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createGermTurnStep(GermId, newRecord) {
    return async () => {
        try {
            const response = await axios({
                method: 'POST',
                url: `${apiURL}/recipes/germ/${GermId}/turn`,
                data: JSON.stringify(newRecord),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('created');
                dispatch(getGermTurnById(GermId));
                console.log('get Turn Steps List');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function toggleArchiveTurnStep(GermId, StepNumber, Archive) {
    // TODO: Change to archived column
    const body = [
        {
            path: 'archived',
            op: 'add',
            value: Archive
        }
    ];
    return async () => {
        try {
            const response = await axios({
                method: 'PATCH',
                url: `${apiURL}/recipes/germ/${GermId}/turn/${StepNumber}`,
                data: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log('Archived');
                dispatch(getGermTurnById(GermId));
                console.log('Done archive');
            });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
