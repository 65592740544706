// third-party
import { ContentPasteSearchOutlined } from '@mui/icons-material';
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { APP_API_URL } from '../../config';

// ----------------------------------------------------------------------
const apiURL = APP_API_URL;

const initialState = {
    error: null,
    phases: [],
    turnTypes: [],
    fillTypes: [],
    productView: [],
    overflowTypes: [],
    aerationTypes: [],
    co2ExtractTypes: [],
    humidifyTypes: [],
    drainTypes: [],
    malthouses: [],
    routes: [],
    // Site
    site: null,
    sites: [],
    siteProperties: null,
    siteBins: [],
    binTypes: [],
    binRoles: [],
    siteBinItems: null,
    lastInvUpdate: null
};

const slice = createSlice({
    name: 'fumesUtils',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // Get Recipe
        getPhaseSuccess(state, action) {
            state.phases = action.payload;
        },

        // Get Recipe
        getTurnTypeSuccess(state, action) {
            state.turnTypes = action.payload;
        },

        getProductViewSuccess(state, action) {
            state.productView = action.payload;
        },

        addProductViewSuccess(state, action) {
        },

        updateProductViewSuccess(state, action) {
        },

        deleteProductViewSuccess(state, action) {
        },

        getFillTypeSuccess(state, action) {
            state.fillTypes = action.payload;
        },

        getOverflowTypeSuccess(state, action) {
            state.overflowTypes = action.payload;
        },

        getAerationTypeSuccess(state, action) {
            state.aerationTypes = action.payload;
        },

        getCo2ExtractTypeSuccess(state, action) {
            state.co2ExtractTypes = action.payload;
        },

        getHumidifyTypeSuccess(state, action) {
            state.humidifyTypes = action.payload;
        },

        getDrainTypeSuccess(state, action) {
            state.drainTypes = action.payload;
        },

        //Malthouse
        getMalthousesSuccess(state, action) {
            state.malthouses = action.payload;
        },

        getStorageLocationsSuccess(state, action) {
            state.storagelocations = action.payload;
        },

        getRoutesSuccess(state, action) {
            state.routes = action.payload;
        },

        // Site
        getSitesSuccess(state, action) {
            state.sites = action.payload;
        },
        getSiteSuccess(state, action) {
            state.site = action.payload;
        },
        getSitePropertiesSuccess(state, action) {
            state.siteProperties = action.payload;
        },
        getSiteBinLocationSuccess(state, action) {
            state.siteBins = action.payload;
        },
        getBinLocationsSuccess(state, action) {
            state.siteBins = action.payload;
        },
        getBinTypesSuccess(state, action) {
            state.binTypes = action.payload;
        },
        getBinRolesSuccess(state, action) {
            state.binRoles = action.payload;
        },
        getItemInLocationSuccess(state, action) {
            state.siteBinItems = action.payload;
        },
        getLastInvUpdateSuccess(state, action) {
            state.lastInvUpdate = action.payload.dts1;
        },
        clearStore(state, action) {
            state.siteBinItems = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export function clearUtilsStore(emptyVal) {
    dispatch(slice.actions.clearStore(emptyVal));
}

// ----------------------------------------------------------------------
export function getPhases() {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/utils/phase`);
            dispatch(slice.actions.getPhaseSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getTurnTypes() {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/utils/turntype`);
            dispatch(slice.actions.getTurnTypeSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getFillTypes() {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/utils/filltype`);
            dispatch(slice.actions.getFillTypeSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProductView() {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/utils/productview`);
            dispatch(slice.actions.getProductViewSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addProductView(product) {
    return async () => {
        try {
            const response = await axios.post(`${apiURL}/utils/productview`, product);
            dispatch(slice.actions.addProductViewSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateProductView(product) {
    return async () => {
        try {
            const response = await axios.post(`${apiURL}/utils/productviewupdate`, product);
            dispatch(slice.actions.updateProductViewSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteProductView(product) {
    return async () => {
        try {
            const response = await axios.post(`${apiURL}/utils/deleteproductview`, product);
            dispatch(slice.actions.deleteProductViewSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getOverflowTypes() {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/utils/overflowtype`);
            dispatch(slice.actions.getOverflowTypeSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// Aeration
export function getAerationTypes() {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/utils/aerationtype`);
            dispatch(slice.actions.getAerationTypeSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createAeration(Aeration) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/utils/aeration/3`);
            dispatch(slice.actions.getSiteSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getC02ExtractTypes() {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/utils/co2Extracttype`);
            dispatch(slice.actions.getCo2ExtractTypeSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getHumidifyTypes() {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/utils/humidifytype`);
            dispatch(slice.actions.getHumidifyTypeSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getDrainTypes() {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/utils/draintype`);
            dispatch(slice.actions.getDrainTypeSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getMalthouses() {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/utils/malthouse`);
            dispatch(slice.actions.getMalthousesSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateMalthouse(malthouse) {
    return async () => {
        try {
            const response = await axios.post(`${apiURL}/utils/malthouseupdate`, malthouse);
            dispatch(slice.actions.getMalthousesSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function addMalthouse(malthouse) {
    return async () => {
        try {
            const response = await axios.post(`${apiURL}/utils/malthouseadd`, malthouse);
            dispatch(slice.actions.getMalthousesSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function deleteMalthouse(malthouse) {
    return async () => {
        try {
            const response = await axios.post(`${apiURL}/utils/malthousedelete`, malthouse);
            dispatch(slice.actions.getMalthousesSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getMalthousesBySite(SiteId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/utils/${SiteId}/malthouse/`);
            dispatch(slice.actions.getMalthousesSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getRoutes() {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/utils/routes`);
            dispatch(slice.actions.getRoutesSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getSiteRoutes(SiteId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/utils/${SiteId}/routes/`);
            dispatch(slice.actions.getRoutesSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// Site
export function getSites() {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/utils/site`);
            dispatch(slice.actions.getSitesSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

//Site Bin Storage Locations
export function getBinLocations() {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/utils/binlocations/`);
            dispatch(slice.actions.getSiteBinLocationSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateBinLocation(malthouse) {
    return async () => {
        try {
            const response = await axios.post(`${apiURL}/utils/binlocationupdate`, malthouse);
            dispatch(slice.actions.getBinLocationsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function addBinLocation(malthouse) {
    return async () => {
        try {
            const response = await axios.post(`${apiURL}/utils/binlocationadd`, malthouse);
            dispatch(slice.actions.getBinLocationsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function deleteBinLocation(malthouse) {
    return async () => {
        try {
            const response = await axios.post(`${apiURL}/utils/binlocationdelete`, malthouse);
            dispatch(slice.actions.getBinLocationsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getSiteBinLocation(SiteId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/utils/${SiteId}/binlocations/`);
            dispatch(slice.actions.getSiteBinLocationSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getBinTypes() {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/utils/bintypes`);
            dispatch(slice.actions.getBinTypesSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getBinRoles() {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/utils/binroles`);
            dispatch(slice.actions.getBinRolesSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getItemInLocation(SiteId, LocationId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/utils/${SiteId}/binlocations/${LocationId}/items`);
            dispatch(slice.actions.getItemInLocationSuccess(response.data && response.data.length === 0 ? [] : response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getSiteProperties(SiteId) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/sites/properties/${SiteId}`);
            dispatch(slice.actions.getSitePropertiesSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createSite(Site) {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/utils/site/3`);
            dispatch(slice.actions.getSiteSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getLastInvUpdate() {
    return async () => {
        try {
            const response = await axios.get(`${apiURL}/utils/LastInvUpdate`);
            dispatch(slice.actions.getLastInvUpdateSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}